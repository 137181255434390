import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { app } from './Firebase';

const Storage = {
  getBackgroudImage: async (imagePath: string) => {
    const storage = getStorage(app);
    const imageRef = ref(storage, imagePath);
    const downloadUrl = await getDownloadURL(imageRef);
    return downloadUrl;
  },
};

export default Storage;
