import React, { useEffect, useState } from 'react';
import './PlutoWeather.css';
import ModalComponent from '../../components/Modal/Modal';
import getPlutoTime from '../../services/Time/Time';
import Firestore from '../../services/Firebase/Firestore';
import SkeletonLoaderComponent from '../../components/SkeletonLoader/SkeletonLoader';
import Storage from '../../services/Firebase/Storage';

const PlutoWeatherPage: React.FC = () => {
  const [data, setData] = useState<any | undefined>(undefined);
  const [whatsThisToolTip, setWhatsThisTooltip] = useState<boolean>(false);
  const [plutoTime, setPlutoTime] = useState('');
  const [bgUrl, setBgUrl] = useState<string>('');

  const whatsThisTooltipOpen = () => setWhatsThisTooltip(true);
  const whatsThisTooltipClose = () => setWhatsThisTooltip(false);

  useEffect(() => {
    setPlutoTime(getPlutoTime());

    document.title = 'Pluto Weather';

    Firestore.getWeather().then((data: any) => {
      setData(data);
      console.log(data);

      Storage.getBackgroudImage(data?.backgroundImage).then((url) => {
        const img = new Image();
        img.onload = () => setBgUrl(url);
        img.src = url;
      });
    });
  }, [setPlutoTime, setData]);

  return (
    <div
      className="h-screen md:max-h-[500px] bg-gray-900 text-white mx-auto max-w-lg mx-auth p-5 shadow-lg weather-bg"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div
        className="absolute p-2 cursor-pointer bg-gray-200/25 w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-300/35"
        onClick={whatsThisTooltipOpen}
      >
        <span>?</span>
      </div>
      <ModalComponent isOpen={whatsThisToolTip} onClose={whatsThisTooltipClose}>
        <div className="text-black text-left">
          <h2 className="text-2xl font-bold text-center">What is this site?</h2>
          <span className="my-2 block">
            <a
              className="underline"
              href="https://medium.com/@gallaghersam95/generative-ai-fantasy-data-and-the-temperature-on-pluto-dc76eaf4c8a5"
              target="_blank"
              rel="noreferrer"
            >
              Here is the article that describes what's going on.
            </a>
          </span>
          <span className="my-2 block">
            This is a thought experiment using Generative AI as a weather
            station for outlandish locations. We don't currently have a weather
            station deployed to the{' '}
            <a
              href="https://en.wikipedia.org/wiki/Belton_Regio"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Belton Regio
            </a>{' '}
            region of Pluto, but a Generative AI model can pretend to be one.
          </span>
          <span className="my-2 block">
            I started with an idea of creating fictional APIs for data that is
            currently inacessible. A system can then take the initial data that
            is generated and feed it back into the system, reinforcing overall
            patterns while generating ongoing data. This idea can be used to
            create all sorts of fictional systems, and I thought a weather
            station on Pluto would be a fun place to start.
          </span>
          <h2 className="text-2xl font-bold text-center">Pluto Time</h2>
          <span className="my-2 block">
            I did real math to approximate the "local" time on Pluto to make
            this more compelling. Pluto has a rotational period of <b>6.387</b>{' '}
            Earth days. For perspective, given our standard 24 hour clock on
            Pluto, it takes 6 hours and 23 minutes of "Earth Time" for 1 hour to
            pass on Pluto.
          </span>
          <span className="my-2 block">
            Given a reference point (Belto Regio, next to Sputnik crater), you
            can approximate when the last "midnight" was from data provided from
            Nasa. From this reference point you can extrapolate out any "Pluto
            Time" given a local Earth Time (in GMT).
          </span>
          <span className="my-2 block">
            This is an approximation, Pluto has complex orbital mechanics. Pluto
            has a fair inclination at 17.16 degrees (from the Sun's equator), an
            axial tile of 122.53 degrees, etc. This complicates the time
            question substantially, but I opted for the "easy" version for this
            test.
          </span>
          <h2 className="text-2xl font-bold text-center">Thanks!</h2>
          <span className="my-2 block">
            Thanks for checking out this experiment! You can find more
            development details on the article above. Feel free to reach out if
            you have any questions or concerns.
          </span>
        </div>
      </ModalComponent>
      <div className="bg-black/50 rounded-lg">
        <h2 className="text-2xl pt-2 font-bold text-center">Belton Regio</h2>
        <h3 className="text-sm text-center">Pluto</h3>
        <div className="mt-4 text-center">
          <span className="text-5xl font-bold">
            {data ? (
              data?.currentTemperature
            ) : (
              <SkeletonLoaderComponent
                width="2em"
                height=".8em"
              ></SkeletonLoaderComponent>
            )}
          </span>
          <span className="text-3xl">{data ? 'F' : ''}</span>
        </div>
        <div className="mt-2 text-center text-lg px-10 py-2">
          {data ? (
            data?.description
          ) : (
            <SkeletonLoaderComponent
              width="8em"
              height="1em"
            ></SkeletonLoaderComponent>
          )}
        </div>
        <div className="mt-4 px-4">
          <div className="flex justify-between">
            <span>Pluto Time</span>
            <span>{plutoTime}</span>
          </div>
          <div className="flex justify-between">
            <span>Wind Gusts</span>
            <span>
              {data ? (
                `${data.currentWind} MPH`
              ) : (
                <SkeletonLoaderComponent
                  width="3em"
                  height="1em"
                ></SkeletonLoaderComponent>
              )}
            </span>
          </div>
          <div className="flex justify-between">
            <span>Pressure</span>
            <span>
              {data ? (
                `${data.currentPressure} PA`
              ) : (
                <SkeletonLoaderComponent
                  width="3em"
                  height="1em"
                ></SkeletonLoaderComponent>
              )}
            </span>
          </div>
        </div>
        <div className="mt-8">
          <h4 className="text-md font-bold text-center">Three Hour Forecast</h4>
          {data ? (
            <div className="max-w-sm mx-auto">
              <div className="flex justify-between items-center px-5 py-3">
                {data.hourlyForecast.map((item: any, index: number) => (
                  <div key={index} className="text-center">
                    <div className="font-medium">{item.plutoTime}</div>
                    <div className="text-lg">{item.averageTemp}F</div>
                    <div className="text-sm">
                      {item.lowTemp}F / {item.highTemp}F
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="max-w-sm mx-auto">
              <SkeletonLoaderComponent
                width="21.5em"
                height="5.5em"
              ></SkeletonLoaderComponent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlutoWeatherPage;
