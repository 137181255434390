import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
} from 'firebase/firestore';
import { app } from './Firebase';

const Firestore = {
  getWeather: async () => {
    const db = getFirestore(app);
    const weatherRef = collection(db, 'pluto-weather');
    const q = query(weatherRef, orderBy('timestamp', 'desc'), limit(1));
    const snap = await getDocs(q);
    let out = undefined;
    snap.forEach((d) => {
      out = d.data();
    });
    return out;
  },
};

export default Firestore;
