import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';

export interface IModal {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
}

const ModalComponent: React.FC<IModal> = ({ isOpen, children, onClose }) => {
  console.log(`MODAL: ${isOpen}`);
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="opacity: 0"
          animate="opacity: 1"
          transition={{ duration: 0.5 }}
          exit="opacity: 0"
          className="absolute bg-white modal-width mx-auto p-6 shadow-lg left-1/2 -translate-x-1/2"
        >
          <div>
            <button
              className="absolute top-o right-0 text-black -translate-x-1/2 bg-gray-300 hover:bg-gray-400 rounded-md p-2 shadow-md"
              onClick={onClose}
            >
              Close
            </button>
          </div>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalComponent;
