import React from 'react';
import './SkeletonLoader.css';

interface ISkeletonLoader {
  width: string;
  height: string;
}

const SkeletonLoaderComponent: React.FC<ISkeletonLoader> = ({
  width,
  height,
}) => {
  return (
    <div
      className="skeleton-block"
      style={{ width: width || '100%', height: height || '100%' }}
    ></div>
  );
};

export default SkeletonLoaderComponent;
