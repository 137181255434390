import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCrYII47ZUsgti3PVAfLDktkxkKNTw7S60',
  authDomain: 'weather-on-plugo.firebaseapp.com',
  projectId: 'weather-on-plugo',
  storageBucket: 'weather-on-plugo.appspot.com',
  messagingSenderId: '818648963124',
  appId: '1:818648963124:web:ec9963c72c1535e4f11261',
  measurementId: 'G-HJ8BJTLB7R',
};

export const app = initializeApp(firebaseConfig);
