import moment from 'moment-timezone';

const getPlutoTime = () => {
  // Get time objects
  const gmtTime = moment.utc();
  const referenceTime = moment.utc('2024-03-30 22:47:00');

  // Get difference
  const minutesPast = Math.floor(
    gmtTime.diff(referenceTime, 'minutes') / 6.387
  );
  console.log(minutesPast);

  // Calculate Pluto Time
  const minutesModulo = minutesPast % 1440;
  console.log(minutesModulo);
  let hours = Math.floor(minutesModulo / 60);
  console.log(hours);
  const minutes = minutesModulo - hours * 60;
  console.log(minutes);

  // AM or PM
  let suffix = 'AM';
  if (hours > 12) {
    hours = hours - 12;
    suffix = 'PM';
  }

  // Minutes
  let minutesStr = `${minutes}`;
  if (minutes < 10) {
    minutesStr = `0${minutes}`;
  }

  return `${hours}:${minutesStr} ${suffix}`;
};

export default getPlutoTime;
